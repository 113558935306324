@tailwind base;
@tailwind components;
@tailwind utilities;

@import "antd/dist/antd.css";

@media (min-width: 768px) {
  .rdrCalendarWrapper.calendar {
    font-size: 14px;
    font-weight: 700;
  }
}

.rdrCalendarWrapper.calendar .rdrDayToday .rdrDayNumber span:after {
  background-color: #8b2f8d;
}

.rdrCalendarWrapper.calendar .rdrDayDisabled.rdrDay {
  background-color: rgb(238, 238, 238);
}

.rdrCalendarWrapper.calendar .rdrDayDisabled.rdrDay span {
  font-weight: 300;
}

.rdrCalendarWrapper.calendar .rdrDay span {
  color: #3d3b3b;
  font-weight: 600;
}

.rdrCalendarWrapper.calendar .rdrDay.rdrDayPassive {
  position: relative;
}

.rdrCalendarWrapper.calendar .rdrDay.rdrDayPassive {
  pointer-events: all;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@layer components {
  .min-h-calc-100vh-96px {
    min-height: calc(100vh - 96px);
  }

  /* Designing for scroll-bar */
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f3e3f4;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #8b2f8d;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* bookslot.jsx bubble animation on booking slot successfully*/

.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
}
.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  background: rgb(156, 243, 236);
  border-radius: 50%;
  opacity: 0.5;
  animation: rise 10s infinite ease-in;
}
.bubble:nth-child(1) {
  width: 40px;
  height: 40px;
  left: 10%;
  animation-duration: 8s;
  background: #ea0181;
}
.bubble:nth-child(2) {
  width: 20px;
  height: 20px;
  left: 20%;
  animation-duration: 5s;
  animation-delay: 1s;
  background: #ea0181;
}
.bubble:nth-child(3) {
  width: 50px;
  height: 50px;
  left: 35%;
  animation-duration: 7s;
  animation-delay: 2s;
  background: #ea0181;
}
.bubble:nth-child(4) {
  width: 80px;
  height: 80px;
  left: 50%;
  animation-duration: 11s;
  animation-delay: 0s;
  background: #ea0181;
}
.bubble:nth-child(5) {
  width: 35px;
  height: 35px;
  left: 55%;
  animation-duration: 6s;
  animation-delay: 1s;
}
.bubble:nth-child(6) {
  width: 45px;
  height: 45px;
  left: 65%;
  animation-duration: 8s;
  animation-delay: 3s;
}
.bubble:nth-child(7) {
  width: 90px;
  height: 90px;
  left: 70%;
  animation-duration: 12s;
  animation-delay: 2s;
  background: #0363a3;
}
.bubble:nth-child(8) {
  width: 25px;
  height: 25px;
  left: 80%;
  animation-duration: 6s;
  animation-delay: 2s;
  background: #4b499b;
}
.bubble:nth-child(9) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(10) {
  width: 90px;
  height: 90px;
  left: 25%;
  animation-duration: 10s;
  animation-delay: 4s;
}
@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translate(100px);
  }
  100% {
    bottom: 1080px;
    transform: translateX(-200px);
  }
}
